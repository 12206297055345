
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  confirmationMessage: string;
}

@Injectable()
export class SaveChangesGuard  {
  constructor() {}
  message: string;
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return component.canDeactivate()
      ? true
      : confirm(component.confirmationMessage);
  }
}
